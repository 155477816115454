export const MENU_ITEMS: MenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'fa-regular fa-house',
    link: '/dashboard',
  },
];

export const SUPER_USER_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'fa-regular fa-house',
    link: '/gtadmin/dashboard',
  },
  {
    title: 'Support',
    icon: 'message-square-outline',
    link: '/gtadmin/support',
  },
  {
    title: 'Subscribers',
    icon: 'people-outline',
    link: '/gtadmin/subscribers',
  },
  {
    title: 'App Users',
    icon: 'person-done-outline',
    link: '/gtadmin/app-users',
  },
  {
    title: 'Beta Users',
    icon: 'people-outline',
    link: '/gtadmin/beta-users',
  },
  {
    title: 'Releases',
    icon: 'code-download-outline',
    link: '/gtadmin/releases',
  },

  {
    title: 'Subscription Plans',
    icon: 'cube-outline',
    link: '/gtadmin/subscription-plans',
  },

  {
    title: 'App Routes',
    icon: 'options-outline',
    link: '/gtadmin/app-routes',
  },
  {
    title: 'Job Status',
    icon: 'save-outline',
    link: '/gtadmin/job-status',
  },
  {
    title: 'Job Types',
    icon: 'save-outline',
    link: '/gtadmin/job-type',
  },
  {
    title: 'Super Users',
    icon: 'person-done-outline',
    link: '/gtadmin/super-users',
  },
];

export const appUserMenu: MenuItem[] = [
  { title: 'Edit Profile', icon: 'fas fa-user', link: '/profile' },

  {
    title: 'Subscription',
    link: '/subscription',
    icon: 'fas fa-user',
  },
  { title: 'Support', link: '/support', icon: 'fas fa-user' },
  { title: 'Change Log', icon: ' ', link: 'updateApp' },
  { title: 'Change Account', icon: '', link: 'changeAccount' },
  { title: 'Sign Out', icon: ' ', link: 'signOut' },
];

export const superUserMenu: MenuItem[] = [
  { title: 'Edit Profile', icon: ' ', link: '/gtadmin/profile' },
  { title: 'Change Log', icon: ' ', link: 'updateApp' },
  { title: 'Sign Out', icon: ' ', link: 'signOut' },
];

export interface MenuItem {
  title: string;
  link: string;
  icon: string;
}
